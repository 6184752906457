import styled from "styled-components"
import { size } from "polished"

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const Insurer = styled.div`
  ${size("11rem", "15rem")}
  margin: 0 1rem 3rem 1rem;
  width: calc(12% - 2rem);

  @media (max-width: 450px) {
    margin-bottom: 2rem;
    width: calc(25% - 2rem);
  }
`
