import styled from "styled-components"

import colors from "../../../styles/colors"

export const Container = styled.form`
  display: flex;
  margin-top: 3rem;

  @media (max-width: 768px) {
    justify-content: center;
  }
`

export const Input = styled.input`
  box-shadow: 0.1rem 0.1rem 0.2rem ${colors.grey1};
  border: 0.1rem solid ${colors.grey1};
  background-color: white;
  color: ${colors.grey2};
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  margin-right: 2rem;
  padding: 1rem 0;
  text-align: center;
  width: 37rem;
`

export const Button = styled.button`
  align-items: center;
  box-shadow: 0.1rem 0.1rem 0.2rem ${colors.grey1};
  background-color: ${colors.green1};
  border: none;
  border-radius: 0;
  color: white;
  display: flex;
  font-size: 1.8rem;
  padding: 0 1rem;
`
