import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container, Banner, About, Insurers } from "../styles/home"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import Title from "../components/Title"
import Text from "../components/Text"
import Products from "../components/Products"
import ProductSearch from "../components/Products/ProductSearch"

import ProductsContext from "../products/context"

import AboutLogo from "../images/about-logo.png"

import InsurerList from "../components/Insurers"

const IndexPage = () => {
  const [keyword, setKeyword] = useState(null)

  const bannerData = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "banners/home.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile: file(relativePath: { eq: "banners/home-mobile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 768, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const bannerSources = [
    bannerData.desktop.childImageSharp.fluid,
    {
      ...bannerData.mobile.childImageSharp.fluid,
      media: `(max-width: 768px)`,
    },
  ]

  return (
    <Layout>
      <ProductsContext.Provider value={{ keyword, setKeyword }}>
        <SEO title="Segurança e Tranquilidade em Primeiro Lugar" />

        <Container>
          <Banner>
            <Img
              fluid={bannerSources}
              style={{ height: "100%" }}
              objectFit="cover"
            />
          </Banner>

          <div className="container">
            <div className="columns is-centered">
              <div className="column is-8 has-text-centered">
                <Title label="Seguros Oferecidos" />
                <Text theme="dark">
                  Oferecemos a você, sua família ou sua empresa as melhores
                  soluções em seguros. Busque abaixo a sua necessidade e
                  encontre o seguro adequado para você!
                </Text>
              </div>
            </div>

            <div className="columns is-centered">
              <div className="columns is-4">
                <ProductSearch />
              </div>
            </div>

            <div className="columns is-centered">
              <div className="column is-10">
                <Products />
              </div>
            </div>
          </div>

          <About>
            <div id="sobre-nos" className="container">
              <div className="columns is-centered">
                <div className="column is-10">
                  <Title label="A Basis" theme="light" />
                  <Text size="big" theme="light" lineHeight={2.6}>
                    A BASIS Corretora de Seguros nasceu com o desejo de oferecer
                    uma experiência ainda mais completa na contratação de seu
                    seguro, garantindo a você, nosso cliente, todo o respaldo
                    quando se fizer necessário. Somos a estrutura, a base
                    sólida, que proporciona o cuidado que você ou sua empresa
                    precisam em seu dia a dia. Estaremos ao seu lado para
                    resolver qualquer tipo de situação, em qualquer tipo de
                    sinistro, com as orientações corretas e realizando todos os
                    procedimentos necessários.
                  </Text>
                  <Text size="big" theme="light" lineHeight={2.6}>
                    Com mais de 10 anos de experiência no mercado, nossos
                    técnicos atuam como consultores de seguros, analisando cada
                    caso individualmente, para assim poder buscar no mercado
                    segurador a melhor opção para cada cliente, sempre com foco
                    em ter um excelente custo benefício e a melhor cobertura.
                    Estaremos a disposição para atendê-lo, não importa o dia e
                    horário.
                  </Text>
                  <Text size="big" theme="light" lineHeight={2.6}>
                    Aqui na BASIS, o que nos move é o desejo de garantir a você
                    o cuidado e resolução de problemas necessários, com
                    agilidade e segurança.
                  </Text>
                  <Text>SUSEP: 202105015</Text>

                  <div className="has-text-centered">
                    <img src={AboutLogo} alt="Basis Seguros" />
                  </div>
                </div>
              </div>
            </div>
          </About>

          <Insurers>
            <div id="seguradoras" className="container">
              <div className="columns">
                <div className="column">
                  <Title label="Seguradoras" />
                  <InsurerList />
                </div>
              </div>
            </div>
          </Insurers>
        </Container>
      </ProductsContext.Provider>
    </Layout>
  )
}

export default IndexPage
