import React from "react"

import { Container, Insurer } from "./styles"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image/withIEPolyfill"

export default () => {
  const insurersLogos = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "insurers" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 150) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Container>
      {insurersLogos.allFile.edges.map(insurer => (
        <Insurer key={insurer.node.id}>
          <Img
            fluid={insurer.node.childImageSharp.fluid}
            style={{ height: "100%", width: "100%" }}
            objectFit="scale-down"
          />
        </Insurer>
      ))}
    </Container>
  )
}
