import styled from "styled-components"

import colors from "./colors"

import AboutBg from "../images/about-bg.png"

export const Container = styled.section``

export const Banner = styled.div`
  height: 70rem;
  margin-bottom: 7rem;

  @media (max-width: 823px) {
    height: 37rem;
  }

  @media (max-width: 450px) {
    height: 24rem;
  }
`

export const About = styled.div`
  background-color: ${colors.blue2};
  background-image: url(${AboutBg});
  background-repeat: no-repeat;
  background-position-x: 20%;
  padding: 7rem 0;

  @media (max-width: 823px) {
    text-align: center;
  }
`
export const Insurers = styled.div`
  background-color: white;
  padding: 7rem 0;

  @media (max-width: 450px) {
    padding: 3rem 0;
  }
`
