import styled from "styled-components"

import { size } from "polished"

import colors from "../../styles/colors"

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 7rem;
`

export const Product = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 5rem 6rem 5rem;
  text-align: center;
  width: calc(25% - 10rem);

  @media (max-width: 823px) {
    margin: 0 1rem 2rem 1rem;
    width: calc(50% - 2rem);
  }
`

export const Icon = styled.div`
  ${size("12rem")}
  align-items: center;
  background-image: linear-gradient(
    180deg,
    ${colors.green1} 0%,
    ${colors.blue1} 100%
  );
  border-radius: 100em;
  color: white;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  svg {
    font-size: 5rem;
  }
`

export const Name = styled.div`
  color: ${colors.blue2};
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-transform: uppercase;
`

export const Description = styled.div`
  color: ${colors.grey3};
  font-weight: 300;
  line-height: 2rem;
  margin-bottom: 1rem;
`
export const Link = styled.a`
  background-color: ${colors.blue1};
  border-radius: 99em;
  color: white;
  display: block;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 2rem;
  margin-top: auto;
  padding: 0.4rem 0;
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;
  width: 100%;
`
