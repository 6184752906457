import React, { useContext, useEffect, useState, useCallback } from "react"

import {
  Container,
  Product,
  Name,
  Description,
  Icon,
  Link as ProductLink,
} from "./styles"

import productsSource from "../../products"

import ProductsContext from "../../products/context"
import { Link } from "gatsby"

export default () => {
  const [products, setProducts] = useState(productsSource)
  const { keyword } = useContext(ProductsContext)

  const filterProducts = useCallback(() => {
    if (!keyword) {
      setProducts(productsSource)
      return
    }

    const nextProducts = productsSource.filter(product =>
      product.tags.match(keyword.toLowerCase(), "ig")
    )

    setProducts(nextProducts)
  }, [keyword])

  useEffect(() => {
    filterProducts()
  }, [filterProducts])

  return (
    <Container>
      {products.map(product => (
        <Product key={product.name}>
          <Link to={product.name}>
            <Icon>{product.icon && <product.icon />}</Icon>
          </Link>
          <Name>{product.label}</Name>
          <Description>{product.shortDescription}</Description>
          <ProductLink
            href={product.link}
            target="_blank"
            rel="noreferrer noopener"
          >
            Solicite sua Cotação
          </ProductLink>
        </Product>
      ))}
    </Container>
  )
}
