import React, { useContext } from "react"

import { Container, Input, Button } from "./styles"

import { FaSearch } from "react-icons/fa"

import ProductsContext from "../../../products/context"

import { useState } from "react"

export default () => {
  const { setKeyword } = useContext(ProductsContext)
  const [keyToSearch, setKeyToSearch] = useState("")

  return (
    <Container
      onSubmit={e => {
        e.preventDefault()
        setKeyword(keyToSearch)
      }}
    >
      <Input
        type="text"
        placeholder="O que você precisa?"
        value={keyToSearch}
        onChange={({ target }) => setKeyToSearch(target.value)}
      />

      <Button type="submit">
        <FaSearch />
      </Button>
    </Container>
  )
}
